//=include '../vendor/jpreloader2/js/jpreloader.js'
//=include '../vendor/slick/slick.min.js'
//=include '../vendor/svg4everybody/svg4everybody.js'
//=include '../vendor/countdown/dest/jquery.countdown.js'
//=include '../vendor/easy-pie-chart/dist/jquery.easypiechart.js'
//=include '../vendor/magnific-popup/dist/jquery.magnific-popup.js'
//=include '../vendor/custom-select/classie.js'
//=include '../vendor/custom-select/selectFx.js'
//=include '../vendor/imagesloaded/imagesloaded.pkgd.js'
//=include '../vendor/isotope/dist/isotope.pkgd.js'
//=include '../vendor/chartjs/chart.min.js'
//=include '../vendor/nouislider/nouislider.min.js'
//=include '../vendor/instafeed/instafeed.js'
//=include '../vendor/social-counters/social-counters-api.js'
